import App from './App.vue'
import router from "./router";
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createApp, h, provide } from 'vue';
import apolloClient from '@/apollo';


const app = createApp({
    setup() {
        provide(DefaultApolloClient, apolloClient)
    },

    render: () => h(App),
})
app.use(router)
app.mount('#app')
