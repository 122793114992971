<script>

import { computed, ref, watch } from "vue";
import { useRoute} from "vue-router";
import gql from "graphql-tag";
import {
  provideApolloClient,
  useQuery
} from "@vue/apollo-composable";
import apolloClient from "@/apollo";
import LoadingSpinner from "@/views/LoadingSpinner.vue";
import PageSection from "@/views/PageSection.vue";

export default {
  components: { PageSection, LoadingSpinner },

  setup() {
    const route = useRoute()
    const query = (pageName) => {
      pageName = pageName.replace(/^\/+/, '')
      const page = pageName ? pageName : null;
      const QUERY_PAGE = gql`
        query Page($name: String) {
           getPage(where: {name: $name} ){
              data{
                section{
                  id
                  title
                  readMore
                  {
                    title
                    pageReference{
                      name
                      title
                    }
                  }
                  sectionImage
                  {
                    altText
                    placement
                    images
                  }
                  sectionVideo{
                    altTitle
                    youtubeLink
                  }
                  text
                }
              }
            }
         }
`
      const { result, loading, error } = useQuery(QUERY_PAGE, {name: page})
      return { result, loading, error };
    }

    const data = ref(query(location.pathname));
    watch(route, () => {
      provideApolloClient(apolloClient);
      data.value = query(route.path);
    });
    const pageData = computed(() => {
      const res = data.value.result.getPage.data
      if(!res){
        location.href = '/404'
      }
      return res || [];
    })

    return { data, pageData };
  },
}
</script>

<template>
  <LoadingSpinner v-if="data.loading" />
  <div class="container" v-else>
    <div v-for="section of pageData.section" :key="section.id">
      <PageSection :section="section"></PageSection>
    </div>
  </div>
</template>

<style scoped>

</style>
