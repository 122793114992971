<template>
  <HeaderPage v-if="!loading" :pages="pages"></HeaderPage>
  <LoadingSpinner v-if="loading" />
  <router-view v-else></router-view>
  <FooterPage :data="footer"></FooterPage>
<!--  <p v-if="error">error: {{ error }}</p>-->
</template>

<script setup>
import '../src/scss/style.scss'
import gql from "graphql-tag"
import { useQuery } from "@vue/apollo-composable"
import { computed, watch} from "vue";
import HeaderPage from "@/views/HeaderPage.vue";
import FooterPage from "@/views/FooterPage.vue";
import PageContent from "@/views/PageContent.vue";
import router from "@/router";
import LoadingSpinner from "@/views/LoadingSpinner.vue";

const QUERY_PAGES = gql`
  query Pages {
    listPages {
      data{
        name
        title
        showInMenu
        homePage
      }
    }
    listPageFooters{
      data{
        id
        leftText
        links{
          id
          name
          title
        }
      }
    }
  }
`

const { result, loading, error } = useQuery(QUERY_PAGES)

const pages = computed(() => {
  const res = result.value?.listPages.data
  let data;
  if(res) {
    data = res.map(p => {
      return Object.assign({url: p.name ? `/${p.name}` : ''}, p);
    })
  }
  return data || [];
})
const footer = computed(() => result.value?.listPageFooters.data[0] || [])
watch(pages, (value)=>{
  value.forEach(p=>{
    if(p.name && p.name) {
      router.addRoute({path: p.url, component: PageContent, meta: { title: `${p.title} - Crowdstake` } })
    }
  })
})
</script>
