<script setup>
import {computed, defineProps} from 'vue';

const temp = defineProps(['section'])
const section = computed(() => temp.section || null)
const image = computed(() => temp.section.sectionImage || null)
const video = computed(() => temp.section.sectionVideo || null)
const readMore = computed(() => temp.section.readMore || null)
const text = computed(() => temp.section.text.root.children.map(e => e.children.map(a => a.text)).reduce((accumulator, value) => accumulator.concat(value), []) || null)
</script>

<template>
  <h2>{{ section.title }}</h2>
  <div class="row" :class="{'flex-row-reverse': image && image.placement === 'left' || video && video.placement === 'left' }">
    <div class="col-12 col-md-6">
      <div v-for="t of text" :key="t.id">
        <div v-html="t"></div>
      </div>
      <a v-if="readMore" :href="'/'+readMore.pageReference.name" class="btn btn-primary btn-lg" role="button">{{ readMore.title }}</a>
    </div>
    <div class="col-12 col-md-6">
      <img v-if="image" :src="image.images" :alt="image.altText"/>
      <div class="embed-responsive embed-responsive-4by3" v-if="video">
        <iframe width="640" height="480" class="embed-responsive-item"
                :src="video.youtubeLink" :title="video.altTitle"
                allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<style scoped>
iframe {
  width: 100%;
  max-width: 100%;
  display: flex;
  border: none;
  background-color: #000;
}
</style>
