<template>
  <div class="d-flex justify-content-center align-items-center loading-block">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</template>

<style scoped>
.loading-block{
  min-height: 400px;
  .spinner-border{
    width: 3rem;
    height: 3rem;
  }
}
</style>
