
<script setup>
import { defineProps } from 'vue';
defineProps(['pages'])
const currentRoute = location.pathname;
const setupDemo = () =>{
  window.Calendly.initPopupWidget({ url: 'https://calendly.com/crowdstake-io/30-minute-meeting-clone', text: 'Schedule time with us!', color: '#20BFF3', textColor: '#FFFFFF', branding: true });
}
</script>

<template>
  <div class="sticky-top top-header">
    <div class="container">
      <header class="d-flex flex-wrap align-content-center justify-content-center py-3 mb-4">
        <router-link to="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
          <img src = "../assets/logo.svg" alt="Crowdstake" title="Crowdstake"/>
        </router-link>
        <ul class="nav d-flex align-content-center">
          <li v-for="page of pages.filter(v => v.url)" :key="page.id" class="nav-item">
            <router-link class="nav-link" :to="page.url">{{ page.title }}</router-link>
          </li>
          <li>
            <button id="bookDemo" type="button" class="btn btn-primary me-2" @click="setupDemo">Book a demo</button>
          </li>
        </ul>
      </header>
    </div>
  </div>
</template>

<style scoped>
  img{
    width: 250px;
  }
  .top-header{
    background: white;
  }
  p{
    height: 2000px;
  }
  .nav-link{
    &:hover{
      color: #0F46B8;
    }
    &.active{
      color: #333;
    }
  }
</style>
