import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';

const httpLink = createHttpLink({
    uri:process.env.VUE_APP_API ,
    headers: {
        Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
        'Cache-Control': 'max-age=604800'
    },
});

const cache = new InMemoryCache()

const apolloClient = new ApolloClient({
    link: httpLink,
    cache
})
export default apolloClient;
