import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import PageContent from '@/views/PageContent.vue';
import NotFound from '@/views/NotFound.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: PageContent,
    meta: { title: 'Landing - Crowdstake' }
  },
  {
    path: '/404',
    name: 'home',
    component: NotFound
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageContent
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  routes
})
router.beforeEach((to, from) => {
  if(typeof(to.meta?.title) === 'string'){
    document.title = (typeof(to.meta?.title) === 'string') ? to.meta?.title : 'Crowdstake';
  }
})
export default router
