<template>
  <div class="container">
    <h2>404 Not found.</h2>
    <p>The page you are looking for does not exist. Please try again.</p>
  </div>

</template>

<style scoped>

</style>
<script setup lang="ts">
</script>
